import React, { JSX, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthWrapper } from '@/features/core/auth/screens/AuthWrapper';
import { getBoolean } from '@/helpers/common/localStorage';
import { Events } from '@/model/Event';
import { setLoading } from '@/redux/loading/loadingSlice';
import { api } from '@/services/api';
import { REACT_APP_AUTH } from '@/utils/config';
import { EventUI } from './ui';

export const EventScreen: React.FC = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const [element, setElement] = useState<HTMLElement | null>();
  const [event, setEvent] = useState<Events>();
  const history = useNavigate();
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [signed, setSigned] = useState<boolean>(getBoolean(String(REACT_APP_AUTH)));

  const handleEvent = async (): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const { data } = await api.get<Events>(`/event/${id}`);
      setEvent(data);
      // eslint-disable-next-line
    } catch (error) {
      // eslint-disable-next-line
      console.error('Error fetching event:', error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleInscription = (inscriptionId: string): void => {
    history(`/inscrição/${inscriptionId}`);
  };

  const checkPageLoaded = (): void => {
    const interval = setInterval(() => {
      const e = document.getElementById('btnDoLogin');
      if (e && interval) {
        setElement(e);
        clearInterval(interval);
      }
    }, 100);
  };

  useEffect(() => {
    checkPageLoaded();
    // checkLogin();
  }, [signed]);

  useEffect(() => {
    if (element && !signed) {
      element.click();
    }

    // eslint-disable-next-line
  }, [element]);

  useEffect(() => {
    handleEvent();
    // eslint-disable-next-line
  }, []);

  return (
    <AuthWrapper isHomepage={false}>
      {event ? (
        <EventUI event={event} onRegister={handleInscription} />
      ) : (
        <p>Carregando evento...</p>
      )}
    </AuthWrapper>
  );
};

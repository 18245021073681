import React, { JSX } from 'react';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import cx from 'classnames';
import { IconArrow } from '@/assets/icons/IconArrow';

interface AccordionProps {
  type: 'single' | 'multiple';
  children: React.ReactNode;
}

export const Accordion: React.FC<AccordionProps> = ({ type, children }): JSX.Element => (
  <AccordionPrimitive.Root type={type} className="space-y-4">
    {children}
  </AccordionPrimitive.Root>
);

interface AccordionItemProps {
  value: string;
  icon: React.ReactNode;
  title: string;
  children: React.ReactNode;
}

export const AccordionItem: React.FC<AccordionItemProps> = ({
  value,
  icon,
  title,
  children,
}: AccordionItemProps): JSX.Element => (
  <AccordionPrimitive.Item
    value={value}
    className="font-dmsans md:border md:border-neutro-w-150 rounded-[5px] focus:outline-none"
  >
    <AccordionPrimitive.Header className="w-full">
      <AccordionPrimitive.Trigger
        className={cx(
          'group',
          'radix-state-open:rounded-t-lg radix-state-closed:rounded-lg',
          'focus:outline-none',
          'inline-flex w-full items-center justify-between bg-white px-[35px] py-[32px] text-left border border-neutro-w-150 rounded-[5px] md:border-0',
        )}
      >
        <div className="flex justify-start items-center w-[20px]">{icon}</div>
        <span className="text-base font-medium text-neutro-b-400 flex-1 ml-[15px]">{title}</span>
        <IconArrow
          className={cx(
            'h-[10px] w-[16px] text-neutro-b-400',
            'group-radix-state-open:-rotate-180 group-radix-state-closed:duration-300 group-radix-state-open:duration-300',
          )}
        />
      </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
    <AccordionPrimitive.Content className="pt-r1 w-full rounded-b-lg bg-white md:border-t md:border-t-neutro-w-150 py-10 md:px-[34px]">
      {children}
    </AccordionPrimitive.Content>
  </AccordionPrimitive.Item>
);

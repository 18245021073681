import React, { JSX, useState } from 'react';
import { IconEye, IconEyeClose, IconUser } from '@/assets/icons';
import { IconLock } from '@/assets/icons/IconLock';
import { IconPin } from '@/assets/icons/IconPin';
import { IconUserCard } from '@/assets/icons/IconUserCard';
import { Accordion, AccordionItem } from '@/components/Accordion';
import { Button } from '@/components/Button';
import { InputText } from '@/components/InputText';
import { Modal } from '@/components/Modal';
import { Select, OptionProps } from '@/components/Select';
import { countryOptions } from '@/components/Country';
import { AuthWrapper } from '@/features/core/auth/screens/AuthWrapper';
import { ConfirmDeleteProfileContent } from '@/features/profile/components/ConfirmDeleteProfileContent';
import {
  ControllerDeleteProfile,
  FormInputName,
  FormInputNameAddress,
  FormInputNamePassword,
  ShowModalProfile,
  UseModalControl,
} from '@/features/profile/types';
import { classNames } from '@/helpers/common';
import { UseFormControl } from '@/types';

interface StatusProps {
  state: boolean;
  controllerPersonalInfo: UseFormControl;
  disableName: boolean;
  disableBirthDate: boolean;
  disableMotherName: boolean;
  controllerChangePassword: UseFormControl;
  controllerAddress: UseFormControl;
  controllerModalProfile: UseModalControl;
  //   controllerCreditCard: ControllerCreditCard;
  controllerDeleteProfile: ControllerDeleteProfile;
}

interface DispatchProps {
  onSubmitPersonalInfo: () => void;
  onSubmitChangePassword: () => void;
  onChangeCEP: (value: string) => void;
  onSubmitAddress: () => void;
}

type ProfileContainerProps = StatusProps & DispatchProps;

export const ProfileContainer: React.FC<ProfileContainerProps> = ({
  state,
  controllerPersonalInfo,
  disableName,
  controllerChangePassword,
  controllerAddress,
  //   controllerCreditCard,
  controllerModalProfile,
  controllerDeleteProfile,
  onSubmitPersonalInfo,
  onSubmitChangePassword,
  onChangeCEP,
  onSubmitAddress,
}: ProfileContainerProps): JSX.Element => {
  const [showPasswordToText, setShowPasswordToText] = useState(false);
  const [showConfirmPasswordToText, setConfirmShowPasswordToText] = useState(false);

  //   const getCardLogo = (cardType: CardType): string => {
  //     let logo = logoMaster;
  //     switch (cardType) {
  //       case CardType.MASTER_CARD:
  //         logo = logoMaster;
  //         break;
  //       case CardType.VISA:
  //         logo = logoVisa;
  //         break;
  //       case CardType.ELO:
  //         logo = logoElo;
  //         break;
  //       case CardType.AMERICAN_EXPRESS:
  //         logo = logoAmex;
  //         break;
  //       case CardType.DISCOVER:
  //         logo = logoDiscover;
  //         break;
  //       case CardType.DINERS_CLUB:
  //         logo = logoDinersClub;
  //         break;
  //       case CardType.JCB:
  //         logo = logoJcb;
  //         break;
  //       default:
  //         break;
  //     }
  //     return logo;
  //   };

  const togglePasswordToText = (): void => {
    setShowPasswordToText(!showPasswordToText);
  };

  const toggleConfirmPasswordToText = (): void => {
    setConfirmShowPasswordToText(!showConfirmPasswordToText);
  };

  const stateOptions: OptionProps[] = [
    { value: 'AC', label: 'Acre' },
    { value: 'AL', label: 'Alagoas' },
    { value: 'AP', label: 'Amapa' },
    { value: 'AM', label: 'Amazonas' },
    { value: 'BA', label: 'Bahia' },
    { value: 'CE', label: 'Ceara' },
    { value: 'DF', label: 'Distrito Federal' },
    { value: 'ES', label: 'Espirito Santo' },
    { value: 'GO', label: 'Goias' },
    { value: 'MA', label: 'Maranhao' },
    { value: 'MT', label: 'Mato Grosso' },
    { value: 'MS', label: 'Mato Grosso do Sul' },
    { value: 'MG', label: 'Minas Gerais' },
    { value: 'PA', label: 'Para' },
    { value: 'PB', label: 'Paraiba' },
    { value: 'PR', label: 'Parana' },
    { value: 'PE', label: 'Pernambuco' },
    { value: 'PI', label: 'Piaui' },
    { value: 'RJ', label: 'Rio de Janeiro' },
    { value: 'RN', label: 'Rio Grande do Norte' },
    { value: 'RS', label: 'Rio Grande do Sul' },
    { value: 'RO', label: 'Rondonia' },
    { value: 'RR', label: 'Roraima' },
    { value: 'SC', label: 'Santa Catarina' },
    { value: 'SP', label: 'Sao Paulo' },
    { value: 'SE', label: 'Sergipe' },
    { value: 'TO', label: 'Tocantins' },
  ];

  return (
    <AuthWrapper isHomepage={false}>
      <div className="w-full p-7 md:px-0 container mx-auto py-24">
        <header className="flex flex-wrap items-center mb-[70px]">
          <IconUser className="mr-[20px] h-[26px]" />
          <h1 className="font-dmsans font-medium text-[37px]">Meu perfil</h1>
          <p className="w-full flex items-center text-[21px] font-dmsans font-normal text-neutro-w-600">
            Verifique ou altere as suas informações
          </p>
        </header>
        <div>
          <Accordion type="multiple">
            <AccordionItem value="aco-1" icon={<IconUserCard />} title="Alterar dados pessoais">
              <div className="mb-[50px]">
                <InputText
                  name="name"
                  label="Nome completo"
                  placeholder="Maria da Silva"
                  className={`appearance-none w-full
                rounded-md text-heading leading-tight focus:outline-none
                focus:shadow-outline text-xs font-dmsans font-normal`}
                  value={controllerPersonalInfo.formData[FormInputName.name]}
                  disabled={disableName}
                  onChange={e =>
                    controllerPersonalInfo.onChangeFormInput(FormInputName.name)(e.target.value)
                  }
                  error={
                    controllerPersonalInfo.formErrors.name &&
                    controllerPersonalInfo.formErrors.name[0]
                  }
                />
              </div>

              <div className="mb-[50px]">
                <InputText
                  name="email"
                  label="E-mail"
                  placeholder="meuemail@email.com"
                  className={`appearance-none w-full
                rounded-md text-heading leading-tight focus:outline-none
                focus:shadow-outline text-xs font-dmsans font-normal`}
                  value={controllerPersonalInfo.formData[FormInputName.nickname]}
                  onChange={e =>
                    controllerPersonalInfo.onChangeFormInput(FormInputName.nickname)(e.target.value)
                  }
                  error={
                    controllerPersonalInfo.formErrors.email &&
                    controllerPersonalInfo.formErrors.email[0]
                  }
                />
              </div>

              <div className="mb-[50px]">
                <InputText
                  name="phone"
                  label="Telefone"
                  placeholder="(00) 0 0000-0000"
                  className={`appearance-none w-full
                rounded-md text-heading leading-tight focus:outline-none
                focus:shadow-outline text-xs font-dmsans font-normal`}
                  value={controllerPersonalInfo.formData[FormInputName.cellPhone]}
                  onChange={e =>
                    controllerPersonalInfo.onChangeFormInput(FormInputName.cellPhone)(
                      e.target.value,
                    )
                  }
                  error={
                    controllerPersonalInfo.formErrors.cellPhone &&
                    controllerPersonalInfo.formErrors.cellPhone[0]
                  }
                />
              </div>

              <div className="mb-[50px]">
                <Button
                  type="submit"
                  title="Salvar"
                  buttonStyle="primary"
                  size="xlg"
                  className=""
                  disabled={
                    state ||
                    controllerPersonalInfo.formData[FormInputName.name] === '' ||
                    controllerPersonalInfo.formData[FormInputName.nickname] === '' ||
                    controllerPersonalInfo.formData[FormInputName.cellPhone] === ''
                  }
                  onClick={() => onSubmitPersonalInfo()}
                />
              </div>
            </AccordionItem>
            <AccordionItem value="aco-2" icon={<IconLock />} title="Alterar minha senha">
              <div className="mb-[30px]">
                <InputText
                  name="password"
                  label="Senha"
                  type={showPasswordToText ? 'text' : 'password'}
                  placeholder="*****"
                  value={controllerChangePassword.formData[FormInputNamePassword.password]}
                  maxLength={15}
                  onChange={e =>
                    controllerChangePassword.onChangeFormInput(FormInputNamePassword.password)(
                      e.target.value,
                    )
                  }
                  error={
                    controllerChangePassword.formErrors.password &&
                    controllerChangePassword.formErrors.password[0]
                  }
                  renderForward={
                    <button
                      className={classNames(
                        controllerChangePassword.formErrors.password &&
                          controllerChangePassword.formErrors.password[0]
                          ? 'right-0 bottom-7'
                          : 'right-0 bottom-3',
                        'absolute cursor-pointer mr-4',
                      )}
                      onClick={togglePasswordToText}
                      type="button"
                    >
                      <div className="h-8 flex flex-col justify-center items-center">
                        {showPasswordToText ? (
                          <img src={IconEye} alt="Mostrar senha" />
                        ) : (
                          <img src={IconEyeClose} alt="Ocultar senha" />
                        )}
                      </div>
                    </button>
                  }
                />
              </div>
              <div className="mb-[50px] text-neutro-w-600 text-base font-dmsans font-normal">
                <p className="mb-5">Sua senha deve conter:</p>
                <ul className="text-neutro-w-500 list-disc list-item ml-6">
                  <li>No mínimo 8 caracteres;</li>
                  <li>Pelo menos um número;</li>
                  <li>Pelo menos um caractere maiúsculo.</li>
                </ul>
              </div>
              <div className="mb-[50px]">
                <InputText
                  name="password"
                  label="Confirme a sua senha"
                  type={showConfirmPasswordToText ? 'text' : 'password'}
                  placeholder="*****"
                  value={controllerChangePassword.formData[FormInputNamePassword.confirmPassword]}
                  maxLength={15}
                  onChange={e =>
                    controllerChangePassword.onChangeFormInput(
                      FormInputNamePassword.confirmPassword,
                    )(e.target.value)
                  }
                  error={
                    controllerChangePassword.formErrors.confirmPassword &&
                    controllerChangePassword.formErrors.confirmPassword[0]
                  }
                  renderForward={
                    <button
                      className={classNames(
                        controllerChangePassword.formErrors.confirmPassword &&
                          controllerChangePassword.formErrors.confirmPassword[0]
                          ? 'right-0 bottom-7'
                          : 'right-0 bottom-3',
                        'absolute cursor-pointer mr-4',
                      )}
                      onClick={toggleConfirmPasswordToText}
                      type="button"
                    >
                      <div className="h-8 flex flex-col justify-center items-center">
                        {showConfirmPasswordToText ? (
                          <img src={IconEye} alt="Mostrar senha" />
                        ) : (
                          <img src={IconEyeClose} alt="Ocultar senha" />
                        )}
                      </div>
                    </button>
                  }
                />
              </div>
              <Button
                type="submit"
                title="Alterar senha"
                buttonStyle="primary"
                size="xlg"
                disabled={
                  controllerChangePassword.formData[FormInputNamePassword.password] === '' ||
                  controllerChangePassword.formData[FormInputNamePassword.confirmPassword] === '' ||
                  state
                }
                onClick={() => onSubmitChangePassword()}
              />
            </AccordionItem>
            <AccordionItem
              value="aco-3"
              icon={<IconPin className="h-[19px]" />}
              title="Alterar meu endereço"
            >
              <div className="mb-[50px]">
                <InputText
                  name="zipCode"
                  label="CEP"
                  placeholder="00000-000"
                  className={`appearance-none w-full
                rounded-md text-heading leading-tight focus:outline-none
                focus:shadow-outline text-xs font-dmsans font-normal`}
                  value={controllerAddress.formData[FormInputNameAddress.zipCode]}
                  maxLength={9}
                  onChange={e => {
                    controllerAddress.onChangeFormInput(FormInputNameAddress.zipCode)(
                      e.target.value,
                    );
                    onChangeCEP(e.target.value);
                  }}
                  error={
                    controllerAddress.formErrors.zipCode && controllerAddress.formErrors.zipCode[0]
                  }
                />
              </div>
              <div className="mb-[50px] grid grid-cols-5 gap-5">
                <Select
                  name="state"
                  label="Estado"
                  placeholder="Selecione..."
                  value={controllerAddress.formData[FormInputNameAddress.state]}
                  onChange={e =>
                    controllerAddress.onChangeFormInput(FormInputNameAddress.state)(
                      e?.target.value as string,
                    )
                  }
                  className="col-span-2"
                  error={
                    controllerAddress.formErrors.state && controllerAddress.formErrors.state[0]
                  }
                  options={stateOptions}
                ></Select>
                <InputText
                  name="city"
                  label="Cidade"
                  placeholder=""
                  className={`appearance-none w-full
                rounded-md text-heading leading-tight focus:outline-none
                focus:shadow-outline text-xs font-dmsans font-normal col-span-3`}
                  value={controllerAddress.formData[FormInputNameAddress.city]}
                  onChange={e =>
                    controllerAddress.onChangeFormInput(FormInputNameAddress.city)(e.target.value)
                  }
                  error={controllerAddress.formErrors.city && controllerAddress.formErrors.city[0]}
                />
              </div>
              <div className="mb-[50px]">
                <InputText
                  name="district"
                  label="Bairro"
                  placeholder=""
                  className={`appearance-none w-full
                rounded-md text-heading leading-tight focus:outline-none
                focus:shadow-outline text-xs font-dmsans font-normal col-span-3`}
                  value={controllerAddress.formData[FormInputNameAddress.district]}
                  onChange={e =>
                    controllerAddress.onChangeFormInput(FormInputNameAddress.district)(
                      e.target.value,
                    )
                  }
                  error={
                    controllerAddress.formErrors.district &&
                    controllerAddress.formErrors.district[0]
                  }
                />
              </div>
              <div className="mb-[50px] grid grid-cols-4 gap-5">
                <InputText
                  name="street"
                  label="Rua/Avenida/Conjunto"
                  placeholder=""
                  className={`appearance-none w-full
                rounded-md text-heading leading-tight focus:outline-none
                focus:shadow-outline text-xs font-dmsans font-normal col-span-3`}
                  value={controllerAddress.formData[FormInputNameAddress.street]}
                  onChange={e =>
                    controllerAddress.onChangeFormInput(FormInputNameAddress.street)(e.target.value)
                  }
                  error={
                    controllerAddress.formErrors.street && controllerAddress.formErrors.street[0]
                  }
                />
                <InputText
                  name="number"
                  label="Número"
                  placeholder=""
                  className={`appearance-none w-full
                rounded-md text-heading leading-tight focus:outline-none
                focus:shadow-outline text-xs font-dmsans font-normal`}
                  value={controllerAddress.formData[FormInputNameAddress.number]}
                  onChange={e =>
                    controllerAddress.onChangeFormInput(FormInputNameAddress.number)(e.target.value)
                  }
                  error={
                    controllerAddress.formErrors.number && controllerAddress.formErrors.number[0]
                  }
                />
              </div>
              <div className="mb-[50px] grid grid-cols-4 gap-5">
                <InputText
                  name="complement"
                  label="Complemento (opcional)"
                  placeholder=""
                  className={`appearance-none w-full
                rounded-md text-heading leading-tight focus:outline-none
                focus:shadow-outline text-xs font-dmsans font-normal col-span-3`}
                  value={controllerAddress.formData[FormInputNameAddress.complement]}
                  onChange={e =>
                    controllerAddress.onChangeFormInput(FormInputNameAddress.complement)(
                      e.target.value,
                    )
                  }
                  error={
                    controllerAddress.formErrors.complement &&
                    controllerAddress.formErrors.complement[0]
                  }
                />
                <Select
                  name="country"
                  label="Pais"
                  placeholder="Selecione..."
                  value={controllerAddress.formData[FormInputNameAddress.country]}
                  onChange={e =>
                    controllerAddress.onChangeFormInput(FormInputNameAddress.country)(
                      e?.target.value as string,
                    )
                  }
                  className={`appearance-none w-full
                rounded-md text-heading leading-tight focus:outline-none
                focus:shadow-outline text-xs font-dmsans font-normal`}
                  error={
                    controllerAddress.formErrors.country && controllerAddress.formErrors.country[0]
                  }
                  options={countryOptions}
                ></Select>
              </div>
              <div className="mb-[0px]">
                <Button
                  type="submit"
                  title="Salvar"
                  buttonStyle="primary"
                  size="xlg"
                  className=""
                  disabled={
                    state ||
                    controllerAddress.formData[FormInputNameAddress.zipCode] === '' ||
                    controllerAddress.formData[FormInputNameAddress.state] === '' ||
                    controllerAddress.formData[FormInputNameAddress.city] === '' ||
                    controllerAddress.formData[FormInputNameAddress.district] === '' ||
                    controllerAddress.formData[FormInputNameAddress.street] === '' ||
                    controllerAddress.formData[FormInputNameAddress.number] === ''
                  }
                  onClick={onSubmitAddress}
                />
              </div>
            </AccordionItem>
            {/* <AccordionItem
              value="aco-5"
              icon={<IconTrash className="h-[16px]" />}
              title="Excluir minha conta"
            >
              <h2 className="font-dmsans font-medium text-neutro-b-400 text-[21px] mb-[20px] leading-[27px]">
                Veja o que vai acontecer ao excluir a sua conta
              </h2>
              <p className="font-dmsans text-base font-normal text-neutro-w-600 mb-[40px]">
                Você <strong>perderá todos os dados pernamentemente</strong>,
                como notas fiscais, lançamentos e histórico de compras. Após
                excluir a conta, <strong>não será possível recuperar</strong> a
                conta e as informações contidas nela.
              </p>
              <Button
                type="button"
                title="Entendi e quero excluir a minha conta"
                buttonStyle="primary"
                size="xlg"
                className=""
                onClick={controllerDeleteProfile.onShowDeleteProfile}
              />
            </AccordionItem> */}
          </Accordion>
        </div>
      </div>
      <Modal
        visible={controllerModalProfile.visible}
        title={controllerModalProfile.title}
        onSetVisible={controllerModalProfile.onToggleModal}
        actions={[]}
      >
        {
          {
            [ShowModalProfile.CONFIRM_DELETE_PROFILE]: (
              <ConfirmDeleteProfileContent
                onCancelConfirm={controllerModalProfile.onToggleModal}
                onOkConfirm={controllerDeleteProfile.onSubmitDeleteProfile}
              />
            ),
          }[controllerModalProfile.shouldShowModal]
        }
      </Modal>
    </AuthWrapper>
  );
};

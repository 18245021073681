import React, { useEffect, useState, JSX } from 'react';
import { useDispatch } from 'react-redux';
import { TrainingForMestre } from '@/model/TrainingForMestre';
import { setLoading } from '@/redux/loading/loadingSlice';
import { api } from '@/services/api';
import { AuthWrapper } from '../../../core/auth/screens/AuthWrapper';
import { HomeContainer } from './ui';

export const HomeScreen: React.FC = (): JSX.Element => {
  const [training, setTrainings] = useState<TrainingForMestre[]>([]);
  const dispatch = useDispatch();

  const getTraining = async (): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const { data } = await api.get<TrainingForMestre[]>('/training/training/mestres');
      setTrainings(data);
      // eslint-disable-next-line
    } catch (error) {
      // eslint-disable-next-line
      console.error('Erro ao buscar treinamentos:', error);
      dispatch(setLoading(false));
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    getTraining();
    // eslint-disable-next-line
  }, []);
  return (
    <AuthWrapper isHomepage>
      <HomeContainer training={training} />
    </AuthWrapper>
  );
};

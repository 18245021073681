import { AuthWrapper } from '@/features/core/auth/screens/AuthWrapper';
import { getBoolean } from '@/helpers/common/localStorage';
import { useDialog } from '@/hooks/useDialog';
import { Checkout } from '@/model/Checkout';
import { Events } from '@/model/Event';
import { Order } from '@/model/Order';
import { Client } from '@/model/User';
import { setLoading } from '@/redux/loading/loadingSlice';
import { api } from '@/services/api';
import { REACT_APP_AUTH } from '@/utils/config';
import React, { JSX, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CheckoutUI } from './ui';

export const CheckoutScreen: React.FC = (): JSX.Element => {
  const [element, setElement] = useState<HTMLElement | null>();
  const { id } = useParams<{ id: string }>();
  const [event, setEvent] = useState<Events>();
  const [img, setImg] = useState<string>();
  const [pix, setPix] = useState<string>();
  const [mestre, setMestre] = useState<string>();
  const history = useNavigate();
  const [signed, setSigned] = useState<boolean>(getBoolean(String(REACT_APP_AUTH)));
  const [user, setUser] = useState<Client>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { visible, onSetVisible, onToggle, title, onChangeTitle } = useDialog();

  const dispatch = useDispatch();

  const getEvent = async (): Promise<void> => {
    if (signed) {
      dispatch(setLoading(true));
      try {
        const response = await api.get<Checkout>(`/event/event-inscription/${id}`);
        if (response.status === 200) {
          setMestre(response.data.mestre.nickname);
          setEvent(response.data.event);
          setImg(response.data.imagePix);
          setPix(response.data.numberPix);
        }
        // eslint-disable-next-line
      } catch (error) {
        // eslint-disable-next-line
        console.error('Erro ao buscar o evento:', error);
      } finally {
        dispatch(setLoading(false));
      }
    }
  };
  const isAuth = async (): Promise<void> => {
    setTimeout(async () => {
      if (getBoolean(String(REACT_APP_AUTH)) !== signed) {
        setSigned(getBoolean(String(REACT_APP_AUTH)));
        getEvent();
      } else {
        isAuth();
      }
    }, 500);
  };
  const getUser = async (): Promise<void> => {
    if (signed) {
      dispatch(setLoading(true));
      try {
        const response = await api.get<Client>(`/user/perfil`);
        if (response.status === 200) {
          setUser(response.data);
        }
        // eslint-disable-next-line
      } catch (error) {
        // eslint-disable-next-line
        console.error('Erro ao buscar o evento:', error);
      } finally {
        dispatch(setLoading(false));
      }
    } else {
      isAuth();
    }
  };

  const handleInscription = async (): Promise<void> => {
    if (element && !signed) {
      element.click();
    }
    if (signed) {
      try {
        dispatch(setLoading(true));

        const response = await api.post<Order>('/order', id);
        if (response.status === 201) {
          toast.success('Inscrição realizada com Sucesso!');
          const orderId = response.data.id;
          history(`/ingressos/detalhe/${orderId}`);
        } else if (response.status === 400) {
          const errorMessage =
            typeof response.data === 'string' ? response.data : JSON.stringify(response.data);
          toast.error(errorMessage);
        }
        // eslint-disable-next-line
      } catch (error: any) {
        // eslint-disable-next-line
        console.error(error);
        let errorMessage = 'Falha ao realizar inscrição, tente novamente mais tarde';
        if (error.response && error.response.data) {
          errorMessage =
            typeof error.response.data === 'string'
              ? error.response.data
              : JSON.stringify(error.response.data);
        }
        toast.error(errorMessage);
      } finally {
        dispatch(setLoading(false));
      }
    } else {
      isAuth();
    }
  };

  const handleCupon = (): void => {
    // eslint-disable-next-line
    console.log('entrou aqui');
  };

  const checkPageLoaded = (): void => {
    const interval = setInterval(() => {
      const e = document.getElementById('btnDoLogin');
      if (e && interval) {
        setElement(e);
        clearInterval(interval);
      }
    }, 100);
  };

  useEffect(() => {
    getEvent();
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    getUser();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    checkPageLoaded();
    // checkLogin();
  }, [signed]);

  useEffect(() => {
    if (element && !signed) {
      element.click();
    }

    // eslint-disable-next-line
  }, [element]);

  return (
    <AuthWrapper isHomepage={false}>
      <CheckoutUI
        event={event}
        imagePix={img || ''}
        pixNumber={pix || ''}
        mestre={mestre || ''}
        inscription={handleInscription}
        cupon={handleCupon}
        user={user}
      />
    </AuthWrapper>
  );
};

export interface UserParam {
  id: string;
}

export interface UseModalControl {
  visible: boolean;
  titleDefine: string | React.ReactNode;
  shouldShowModal: ShowModalTickets;
  onShouldShowModal: ({ value, title }: ShouldShowModalProps) => void;
  onToggleModal: () => void;
  onSetVisible: (visible: boolean) => void;
}

export type ShouldShowModalProps = {
  value: ShowModalTickets;
  title: string | React.ReactNode;
};

export enum ShowModalTickets {
  TICKET_CANCEL_AFTER = 'TICKET_CANCEL_AFTER',
}

export interface ControllerTicketCancel {
  onShowTicketCancel: () => void;
  onCancelTicketCancel: () => void;
  onSubmitTicketCancel: () => void;
}

export enum FormInputName {
  reason = 'reason',
}

export enum FormInputNameRegister {
  id = 'id',
  name = 'name',
  document = 'document',
  email = 'email',
  phone = 'phone',
  birthDate = 'birthDate',
  motherName = 'motherName',
}

export enum FormInputNameFilter {
  date = 'date',
  name = 'name',
}

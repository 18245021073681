import React, { JSX } from 'react';
import { Events } from 'src/model/Event';
import { Client } from 'src/model/User';
import dayjs from 'dayjs';
import { Button } from '@/components/Button';
import DOMPurify from 'dompurify';
import { toast } from 'react-toastify';

interface CheckoutUIProps {
  event?: Events;
  user?: Client;
  imagePix: string;
  pixNumber: string;
  mestre: string;
  inscription: () => void;
  cupon: () => void;
}

export const CheckoutUI: React.FC<CheckoutUIProps> = ({
  event,
  imagePix,
  pixNumber,
  inscription,
  cupon,
  //   user,
  mestre,
}): JSX.Element => {
  const copyToClipboard = (): void => {
    navigator.clipboard.writeText(pixNumber);
    toast.success('Número do Pix copiado para a área de transferência!');
  };

  const sanitizedDescription = event ? DOMPurify.sanitize(event.description) : '';

  return (
    <div className="flex flex-col items-center justify-center p-6 bg-gray-100 min-h-screen">
      <div className="flex flex-col lg:flex-row lg:space-x-6 lg:items-start max-w-4xl w-full">
        {/* Dados do Evento */}
        {event && (
          <div className="bg-white p-6 rounded-lg shadow-md mb-6 lg:mb-0 lg:w-1/2">
            <img
              src={`https://www.ngolocapoeira.org/uploads/'${event.image}`}
              alt="name"
              className="w-full h-80 object-cover rounded-lg mb-4"
            />

            <h1 className="text-2xl font-bold mb-2">{event.name}</h1>
            <div
              className="text-lg md:text-sm text-gray-700 mb-4"
              dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
            />
            <p className="text-gray-600 mb-2"></p>
            <p className="text-gray-600 mb-2">
              <span className="font-bold">Data:</span>
              {`${dayjs(event.startDate).format('DD/MM/YYYY')}  à ${dayjs(event.endDate).format(
                'DD/MM/YYYY',
              )}`}
            </p>
            <p className="text-gray-600">
              <span className="font-bold">Status:</span> Ativo
            </p>
          </div>
        )}
        {/* Pagamento e Cupom de Desconto */}
        <div className="flex flex-col lg:w-1/2">
          {/* QR Code e Número do Pix */}
          <div className="bg-white p-6 rounded-lg shadow-md mb-6">
            <hr />
            <h2 className="text-xl font-semibold mb-4 mt-2">Pague com Pix</h2>
            <div className="flex justify-center">
              <h2 className="text-xl font-semibold mb-4">Mestre {mestre || ''}</h2>
            </div>
            <div className="flex justify-center mb-4">
              <img
                src={`https://www.ngolocapoeira.org/uploads/${imagePix}`}
                alt="QR Code para pagamento via Pix"
                className="w-48 h-48"
              />
            </div>
            <p className="text-gray-600 text-center mb-4">
              Escaneie o QR Code acima para efetuar o pagamento via Pix.
            </p>
            <div className="bg-gray-200 p-4 rounded-md text-center mb-4">
              <p className="text-gray-700 mb-2">Número do Pix:</p>
              <span className="block text-gray-900 break-words">{pixNumber}</span>
              <button
                onClick={copyToClipboard}
                className="mt-2 bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600"
              >
                Copiar Número
              </button>
            </div>
            <Button onClick={inscription} title="Inscrição" buttonStyle="primary" size="xlg" />
          </div>

          {/* Cupom de Desconto */}
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-4">Tenho Cupom de Inscrição</h2>
            <input
              type="text"
              placeholder="Digite seu cupom"
              className="w-full p-2 border border-gray-300 rounded-md mb-4"
            />
            <Button onClick={cupon} title="Inscrição com Cupom" buttonStyle="primary" size="xlg" />
          </div>
        </div>
      </div>
    </div>
  );
};

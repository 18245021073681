import React, { JSX } from 'react';
import { Button } from '@/components/Button';
import { Events } from '@/model/Event';

// eslint-disable-next-line
const htmlToText = (html: string) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
};

const truncateText = (text: string, maxLength: number): string => {
  if (text.length <= maxLength) return text;
  return `${text.substring(0, maxLength)}...`;
};

interface EventUIProps {
  event: Events[];
  inscription: (id: string) => void;
}

export const EventsUI: React.FC<EventUIProps> = ({ event, inscription }): JSX.Element => (
  <section className="w-full">
    <div className="container w-full md:max-w-[1110px] mx-auto flex flex-col items-center">
      <div className="p-8 md:flex-shrink-0">
        <div className="text-[#013209] font-extrabold text-3xl sm:text-4xl lg:text-5xl tracking-tight text-center dark:text-white mb-7 ">
          Eventos
        </div>
        {event && event.length > 0 ? (
          event.map((data, index) => {
            // Remove HTML tags and truncate description
            const textDescription = htmlToText(data.description);
            const truncatedDescription = truncateText(textDescription, 100);

            return (
              <div
                key={index}
                className="max-w-sm mx-auto bg-white shadow-md rounded-lg overflow-hidden mb-4"
              >
                <img
                  className="w-full h-auto object-cover"
                  src={`https://www.ngolocapoeira.org/uploads/${data.image}`}
                  alt={data.name}
                />
                <div className="p-4">
                  <h2 className="text-xl font-bold mb-2">{data.name}</h2>
                  <p className="text-gray-700 text-base mb-4">{truncatedDescription}</p>
                  <div className="text-gray-500 text-sm">
                    <p></p>
                    <p>
                      {new Date(data.startDate).toLocaleDateString()} -{' '}
                      {new Date(data.endDate).toLocaleDateString()}
                    </p>
                    {data.value && (
                      <p className="text-gray-700 text-base mb-4 mt-5">
                        Valor: {`R$ '${data.value},00`}
                      </p>
                    )}
                  </div>
                </div>
                <div className="p-4">
                  <Button
                    title="Ver evento"
                    buttonStyle="primary"
                    size="xlg"
                    className="w-full"
                    onClick={() => inscription(data.id)}
                  />
                </div>
              </div>
            );
          })
        ) : (
          <div> Sem Event</div>
        )}
      </div>
    </div>
  </section>
);

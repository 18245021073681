import React, { JSX } from 'react';

export const GroupUI: React.FC<object> = (): JSX.Element => (
  <>
    <section className="w-full">
      <div className="container w-full md:max-w-[1110px] mx-auto flex flex-col items-center">
        <div className="p-8 md:flex-shrink-0">
          <div className="text-[#013209] font-extrabold text-3xl sm:text-4xl lg:text-5xl tracking-tight text-center dark:text-white ">
            HISTÓRIA DO GRUPO N&apos;GOLO CAPOEIRA
          </div>
          <div className="mt-2 flex flex-col md:flex-row items-center">
            <div className="text-lg  ">
              <p className="mt-6 text-[#013209] mx-auto dark:text-slate-400 font-black">FUNDAÇÃO</p>
              <p className="mt-6 text-[#013209] mx-auto dark:text-slate-400">
                Em novembro de 1987, Mestre Dionízio iniciava no Centro Educacional 02 na cidade
                Guará (hoje Centro de Ensino Médio 01 - CEM 01 do Guará) em Brasília, um trabalho
                com capoeira na escola denominado CAC (Centro de Aprendizagem de Capoeira),
                vinculado direta e oficialmente à Secretaria de Estado de Educação, na época
                denominada FEDF (Fundação Educacional do DF). Em 07 de fevereiro de 2004 foi
                registrado o Centro Cultural Arte Luta N&apos;golo Capoeira, como Entidade sem fins
                Lucrativos e personalidade jurídica.
              </p>
              <p className="mt-6 text-[#013209] mx-auto dark:text-slate-400">
                O projeto atendia alunos da rede pública de ensino no horário contrário as aulas,
                dando direito ao praticante ser avaliado bimestralmente substituindo o conceito
                obrigatório das aulas de educação física. O projeto tem sua origem em iniciativas
                implementadas pelo mestre ZULU junto a FEDF, que depois de muito esforço conseguiu a
                aprovação e sua implantação experimental em janeiro de 1982, no Colégio Agrícola de
                Brasília. Em 1992 com o funcionamento de quatro Centros o projeto foi incorporado ao
                programa dos CIDs - Centro de Iniciação Desportiva, que passou a recebera
                denominação de Centro de Iniciação Desportiva em Capoeira. O CID é um programa
                implantado pelo MEC em 1983, que tinha a Fundação como a executora, mas em 1985 o
                programa foi desvinculado do MEC passando a ser gerido apenas pela Fundação, assim
                sendo, a capoeira hoje se constitui em mais uma opção entre as demais modalidades
                oferecidas pelo CID em todas as regionais de ensino do Distrito Federal, sendo que
                atualmente tem apenas três CIDs de capoeira, no Guará, Sobradinho e Núcleo
                Bandeirante.
              </p>
              <p className="mt-6 text-[#013209] mx-auto dark:text-slate-400">
                Para atuar no CID de capoeira o professor deve ser pertencente do quadro efetivo do
                magistério através de concurso público, como também ser mestre ou contra-mestre de
                capoeira.
              </p>
              <p className="mt-6 text-[#013209] mx-auto dark:text-slate-400">
                Além dessa vinculação com a Secretaria de Educação, o nosso trabalho esteve também
                até o dia 13 de outubro de 2003 vinculado ao Grupo de Capoeira Beribazu, onde
                tínhamos o grupo como um grande parceiro no desenvolvimento de todo o trabalho.
              </p>
              <p className="mt-6 text-[#013209] mx-auto dark:text-slate-400">
                Depois da desvinculação do Grupo Beribazu, iniciamos no dia 07 de fevereiro de 2004
                juntamente com os alunos mais graduados discussões sobre a criação ou não, de um
                nome para o grupo que já existia de fato, pois todos iniciados e pertencentes a esse
                trabalho de capoeira na escola também já atuam como docentes de capoeira,
                ministrando aulas em vários locais em Brasília e em algumas cidades como São Paulo,
                Gravatá-PE, Imperatriz-MA e até no Japão.
              </p>
              <p className="mt-6 text-[#013209] mx-auto dark:text-slate-400">
                Desta forma, optamos pela criação de um nome que surgisse das sugestões que
                porventura fossem dadas por qualquer aluno integrante do grupo. Foram quatro meses
                colhendo sugestões e no dia 10 de julho de 2004, diante de seis nomes, escolhemos
                por maioria de voto o nome N&apos;golo, que passou então a ser composto de Centro
                Cultural Arte Luta N&apos;golo Capoeira. Centro Cultural - pela nossa proposta de
                trabalhar pela difusão e desenvolvimento da capoeira como cultura nacional, Arte
                Luta - por sermos oriundos da vertente arte luta, fundamentada pelo Mestre Zulu e
                optarmos por estar a ela vinculados.
              </p>
              <p className="mt-6 text-[#013209] mx-auto dark:text-slate-400 font-black">
                SIGNIFICADO DO NOME
              </p>
              <p className="mt-6 text-[#013209] mx-auto dark:text-slate-400">
                N&apos;golo - era um ritual praticado por tribos do sul da África principalmente os
                Humbes e Mucupes, durante a{'efúndula'} (quando meninas passam a condição de
                mulher), as jovens eram disputadas entre jovens guerreiros em luta corpo a corpo, ao
                vencedor cabia o direito de escolher sua esposa, sem o pagamento do dote
                matrimonial.
              </p>
              <p className="mt-6 text-[#013209] mx-auto dark:text-slate-400">
                O N&apos;golo - traduzido também como dança da zebra, junto com cujuinha, uiganga e
                cuissamba são dentre outros elementos motrizes africanos, formadores do substrato
                estético-gestual da capoeira (mestre Zulu - Idiopráxis de Capoeira), 1995 p. 02.
              </p>
              <p className="mt-6 text-[#013209] mx-auto dark:text-slate-400">
                A dança da zebra era, pois movimentos imitados com os da zebra em luta ou em
                carreira ou ainda das zebras machos para conquistar uma fêmea no cio.
              </p>
              <p className="mt-6 text-[#013209] mx-auto dark:text-slate-400">
                Na língua Kikongo em algumas tribos do sul da Angola N&apos;golo significa: FORÇA ou
                PODER.
              </p>
              <p className="mt-6 text-[#013209] mx-auto dark:text-slate-400">
                (Mestre Dionizio - Fundador do Grupo N&apos;golo Capoeira)
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
);

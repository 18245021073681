import React, { JSX } from 'react';
import { FaDownload } from 'react-icons/fa';

export const ArticlesUI: React.FC = (): JSX.Element => {
  const articles = [
    {
      id: 1,
      title:
        'A ESCOLARIZAÇÃO DA CAPOEIRA COMO INDUTORA DA EDUCAÇÃO PARA OS DIREITOS HUMANOS Por Óscar Curros',
      link: 'https://12fbd9cc-7b81-2e6b-6d1a-9d3fa0d8a67b.filesusr.com/ugd/1fe369_0e0f8818635e4376a25c8708b392723f.pdf',
    },
    {
      id: 2,
      title:
        'Inventário para registro e Salvaguarda da Capoeira como Patrimonio Cultural do Brasil',
      link: 'https://12fbd9cc-7b81-2e6b-6d1a-9d3fa0d8a67b.filesusr.com/ugd/1fe369_740ad5cd636e4045b37299de110a300f.pdf',
    },
    {
      id: 3,
      title: 'A Mulher na Capoeira',
      link: 'https://12fbd9cc-7b81-2e6b-6d1a-9d3fa0d8a67b.filesusr.com/ugd/1fe369_ed579f221e4d4a9bb02a57a2180df479.pdf',
    },
    {
      id: 3,
      title: 'Capoeira e memória o culto aos ancestrais como suporte à identidade',
      link: 'https://12fbd9cc-7b81-2e6b-6d1a-9d3fa0d8a67b.filesusr.com/ugd/1fe369_261bc33fa3184d629a0d4c55a90a4842.pdf',
    },
  ];

  return (
    <section className="w-full">
      <div className="container w-full md:max-w-[1110px] mx-auto flex flex-col items-center">
        <div className="p-6">
          <h1 className="text-2xl font-bold mb-4">Artigos para Download</h1>
          <ul className="space-y-4">
            {articles.map(article => (
              <li key={article.id} className="flex items-center bg-white p-4 shadow-md rounded-md">
                <span className="flex-1 mr-3">{article.title}</span>
                <a
                  href={article.link}
                  className="text-blue-500 hover:text-blue-700 flex items-center"
                  download
                  target="blank"
                >
                  <FaDownload className="mr-2" />
                  Download
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

import React, { JSX } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { AuthWrapper } from '@/features/core/auth/screens/AuthWrapper';
import { OrderResponse } from '@/model/OrderResponse';

dayjs.extend(utc);
interface TicketsUIProps {
  order?: OrderResponse;
  paymentStatus: (payment: string) => string;
}

const htmlToText = (html: string): string => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
};

const truncateText = (text: string, maxLength: number): string => {
  if (text.length <= maxLength) return text;
  return `${text.substring(0, maxLength)}...`;
};

export const TicketsUI: React.FC<TicketsUIProps> = ({ order, paymentStatus }): JSX.Element => {
  if (!order) {
    return (
      <div className="flex justify-center items-center h-screen text-lg text-gray-600">
        Carregando...
      </div>
    );
  }

  const textDescription = htmlToText(order.event?.description);
  const truncatedDescription = truncateText(textDescription, 100);

  return (
    <AuthWrapper isHomepage={false}>
      <div className="max-w-3xl mx-auto m-3 p-8 bg-white shadow-lg rounded-lg border border-gray-200">
        <h1 className="text-4xl font-bold text-center text-gray-600 mb-8">
          Confirmação de Inscrição
        </h1>

        <div className="mb-6 border-b border-gray-300 pb-4">
          <h2 className="text-2xl font-semibold text-gray-600 mb-3">Participante</h2>
          <p className="text-gray-700">
            <strong>Nome:</strong> {order.user?.name ?? 'Nome não disponível'}
          </p>
          <p className="text-gray-700">
            <strong>Email:</strong> {order.user?.email ?? 'Email não disponível'}
          </p>
          <p className="text-gray-700">
            <strong>Telefone:</strong> {order.user?.cellPhone ?? 'Telefone não disponível'}
          </p>
        </div>

        <div className="mb-6 border-b border-gray-300 pb-4">
          <h2 className="text-2xl font-semibold text-gray-600 mb-3">Evento</h2>
          <p className="text-gray-700">
            <strong>Nome:</strong> {order.event?.name ?? 'Nome não disponível'}
          </p>
          <p className="text-gray-700">
            <strong>Descrição:</strong> {truncatedDescription ?? 'Descrição não disponível'}
          </p>
          <p className="text-gray-600 mb-6">
            <span className="font-bold">Data:</span>{' '}
            {dayjs.utc(order.event?.startDate).format('DD/MM/YYYY')} à{' '}
            {dayjs.utc(order.event?.endDate).format('DD/MM/YYYY')}
          </p>
        </div>

        <div className="mb-6 border-b border-gray-300 pb-4">
          <h2 className="text-2xl font-semibold text-gray-600 mb-3">Detalhes do Pedido</h2>
          <p className="text-gray-700">
            <strong>ID do Pedido:</strong> {order.order?.id ?? 'ID não disponível'}
          </p>
          <p className="text-gray-700">
            <strong>Valor Total:</strong> R$ {order.order?.totalValue ?? 'Não disponível'}
          </p>
          <p className="text-gray-700">
            <strong>Status do Pedido:</strong> {order.order?.status ?? 'Status não disponível'}
          </p>
          <p className="text-gray-700">
            <strong>Status de Pagamento:</strong>{' '}
            {paymentStatus(order.order?.paymentStatus.toString()) ?? 'Status não disponível'}
          </p>
        </div>

        {/* <div className="text-center mt-8">
          <button className="bg-blue-500 text-white px-6 py-3 rounded-lg font-semibold shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-200">
            Imprimir Confirmação
          </button>
        </div> */}
      </div>
    </AuthWrapper>
  );
};

import React, { JSX, useEffect, useState } from 'react';
import validators from '@/helpers/validators';

import cep from 'cep-promise';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { removeItem } from '@/helpers/common/localStorage';
import { updateMask as cepMask } from '@/helpers/masks/cep';
import { updateMask as updateMaskPhone } from '@/helpers/masks/mobilePhone';
import { useDialog } from '@/hooks/useDialog';
import useForm from '@/hooks/useForm';
import { Address } from '@/model/Address';
import CardClient from '@/model/CardClient';
import ChangeClientPassword from '@/model/ChangeClientPassword';
import { Client, UpdateClient } from '@/model/User';
import { setLoading, useLoading } from '@/redux/loading/loadingSlice';
import { api } from '@/services/api';
import { UseFormControl } from '@/types';
import { REACT_APP_AUTH, REACT_APP_USER } from '@/utils/config';
import {
  ControllerDeleteProfile,
  FormInputName,
  FormInputNameAddress,
  FormInputNamePassword,
  ShouldShowModalProps,
  ShowModalProfile,
  UseModalControl,
} from '../../types';
import { ProfileContainer } from './ui';

export const ProfileScreen: React.FC = (): JSX.Element => {
  const { visible, onSetVisible, onToggle, title, onChangeTitle } = useDialog();
  const [shouldShowModal, setShouldShowModal] = useState<ShowModalProfile>(
    ShowModalProfile.CONFIRM_DELETE_PROFILE,
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [client, setClient] = useState<Client>({} as Client);
  const [disableName, setDisableName] = useState<boolean>(false);
  const [disableBirthDate, setDisableBirthDate] = useState<boolean>(false);
  const [disableMotherName, setDisableMotherName] = useState<boolean>(false);
  //   const [cardId, setCardId] = useState<string>(undefined as unknown as string);

  const { loading } = useSelector(useLoading);
  const dispatch = useDispatch();
  const history = useNavigate();

  const {
    formData: formDataAddress,
    formErrors: formErrorsAddress,
    setErrors: setErrorsAddress,
    onChangeFormInput: onChangeFormInputAddress,
    isFormValid: isFormValidAddress,
    resetForm: resetFormAddress,
  } = useForm({
    initialData: {
      zipCode: '',
      state: '',
      city: '',
      district: '',
      street: '',
      complement: '',
      number: '',
      country: '',
    },
    validators: {
      zipCode: [validators.required, validators.maxLength(9)],
      state: [validators.required],
      city: [validators.required],
      district: [validators.required],
      street: [validators.required],
      number: [validators.required],
      country: [validators.required],
    },
    formatters: {
      zipCode: cepMask,
    },
  });

  const {
    formData: formDataPersonalInfo,
    formErrors: formErrorsPersonalInfo,
    setErrors: setErrorsPersonalInfo,
    onChangeFormInput: onChangeFormInputPersonalInfo,
    isFormValid: isFormValidPersonalInfo,
    resetForm: resetFormPersonalInfo,
  } = useForm({
    initialData: {
      name: '',
      nickname: '',
      cellPhone: '',
    },
    validators: {
      name: [validators.required],
      nickname: [validators.required],
      cellPhone: [validators.required, validators.mobilePhone],
    },
    formatters: {
      cellPhone: updateMaskPhone,
    },
  });

  const getUserData = async (): Promise<void> => {
    dispatch(setLoading(true));
    const { data } = await api.get<Client>('/user/perfil');
    setClient(data);
    if (data.checkData) {
      setDisableName(true);
      setDisableBirthDate(true);
      setDisableMotherName(true);
    }
    onChangeFormInputPersonalInfo(FormInputName.name)(data.name ? data.name : '');
    onChangeFormInputPersonalInfo(FormInputName.nickname)(data.nickname ? data.nickname : '');
    onChangeFormInputPersonalInfo(FormInputName.cellPhone)(data.cellPhone ? data.cellPhone : '');

    if (data.address) {
      const address = data.address as Address;
      onChangeFormInputAddress(FormInputNameAddress.id)(address.id);
      onChangeFormInputAddress(FormInputNameAddress.zipCode)(address.zipcode);
      onChangeFormInputAddress(FormInputNameAddress.state)(address.state);
      onChangeFormInputAddress(FormInputNameAddress.city)(address.city);
      onChangeFormInputAddress(FormInputNameAddress.district)(address.district);
      onChangeFormInputAddress(FormInputNameAddress.street)(address.street);
      onChangeFormInputAddress(FormInputNameAddress.number)(address.number);
      onChangeFormInputAddress(FormInputNameAddress.complement)(address.complement);
      onChangeFormInputAddress(FormInputNameAddress.country)(address.country);
    }

    dispatch(setLoading(false));
  };

  const handleOnChangeCEP = async (value: string): Promise<void> => {
    if (value.length === 9) {
      const cepResponse = await cep(value);
      onChangeFormInputAddress(FormInputNameAddress.state)(cepResponse.state);
      onChangeFormInputAddress(FormInputNameAddress.city)(cepResponse.city);
      onChangeFormInputAddress(FormInputNameAddress.district)(cepResponse.neighborhood);
      onChangeFormInputAddress(FormInputNameAddress.street)(cepResponse.street);
    }
  };
  // eslint-disable-next-line
  const showModal = ({ value, title }: ShouldShowModalProps): void => {
    setShouldShowModal(value);
    onChangeTitle(title);
    onSetVisible(true);
  };

  const handleOnSubmitPersonalInfo = async (): Promise<void> => {
    if (isFormValidPersonalInfo()) {
      try {
        dispatch(setLoading(true));
        const clientPersonalData = {
          name: formDataPersonalInfo[FormInputName.name],
          nickname: formDataPersonalInfo[FormInputName.nickname],
          cellPhone: formDataPersonalInfo[FormInputName.cellPhone],
        } as UpdateClient;
        await api.post('user/update-perfil', clientPersonalData);
        toast.success('Dados pessoais alterados com sucesso!');
        // eslint-disable-next-line
      } catch (error) {
        dispatch(setLoading(false));
      } finally {
        dispatch(setLoading(false));
      }
    } else {
      toast.error('Algo deu errado!');
    }
  };

  const controllerPersonalinfo: UseFormControl = {
    formData: formDataPersonalInfo,
    formErrors: formErrorsPersonalInfo,
    setErrors: setErrorsPersonalInfo,
    onChangeFormInput: onChangeFormInputPersonalInfo,
    isFormValid: isFormValidPersonalInfo,
    resetForm: resetFormPersonalInfo,
  };

  const controllerModalPayment: UseModalControl = {
    // eslint-disable-next-line
    visible: visible,
    // eslint-disable-next-line
    title: title,
    shouldShowModal,
    onShouldShowModal: showModal,
    onToggleModal: onToggle,
    onSetVisible,
  };

  const {
    formData: formDataChangePassword,
    formErrors: formErrorsChangePassword,
    setErrors: setErrorsChangePassword,
    onChangeFormInput: onChangeFormInputChangePassword,
    isFormValid: isFormValidChangePassword,
    resetForm: resetFormChangePassword,
  } = useForm({
    initialData: {
      password: '',
      confirmPassword: '',
    },
    validators: {
      password: [
        validators.required,
        validators.minLength(8),
        validators.maxLength(15),
        validators.hasPasswordOnlyNumberCharacteres,
      ],
      confirmPassword: [
        validators.required,
        validators.minLength(8),
        validators.maxLength(15),
        validators.hasPasswordOnlyNumberCharacteres,
      ],
    },
    formatters: {},
  });

  const onSubmitChangePassword = async (): Promise<void> => {
    if (isFormValidChangePassword()) {
      try {
        dispatch(setLoading(true));

        const change: ChangeClientPassword = {
          password: formDataChangePassword[FormInputNamePassword.password],
          confirmPassword: formDataChangePassword[FormInputNamePassword.confirmPassword],
        };

        await api.post('user/password', change);
        resetFormChangePassword();
        toast.success('Senha alterada com sucesso!');
        // eslint-disable-next-line
      } catch (error) {
        dispatch(setLoading(false));
      } finally {
        dispatch(setLoading(false));
      }
    }
  };

  const controllerChangePassoword: UseFormControl = {
    formData: formDataChangePassword,
    formErrors: formErrorsChangePassword,
    setErrors: setErrorsChangePassword,
    onChangeFormInput: onChangeFormInputChangePassword,
    isFormValid: isFormValidChangePassword,
    resetForm: resetFormChangePassword,
  };

  const onSubmitAddress = async (): Promise<void> => {
    if (isFormValidAddress()) {
      try {
        dispatch(setLoading(true));
        const address: Address = {
          id: formDataAddress[FormInputNameAddress.id],
          zipcode: formDataAddress[FormInputNameAddress.zipCode],
          state: formDataAddress[FormInputNameAddress.state],
          city: formDataAddress[FormInputNameAddress.city],
          district: formDataAddress[FormInputNameAddress.district],
          street: formDataAddress[FormInputNameAddress.street],
          complement: formDataAddress[FormInputNameAddress.complement],
          number: formDataAddress[FormInputNameAddress.number],
          country: formDataAddress[FormInputNameAddress.country],
        };
        await api.post('/user/address', address);
        await getUserData();
        dispatch(setLoading(false));
        toast.success('Endereço alterado com sucesso!');
        // eslint-disable-next-line
      } catch (error) {
        dispatch(setLoading(false));
      }
    }
  };

  const controllerAddress: UseFormControl = {
    formData: formDataAddress,
    formErrors: formErrorsAddress,
    setErrors: setErrorsAddress,
    onChangeFormInput: onChangeFormInputAddress,
    isFormValid: isFormValidAddress,
    resetForm: resetFormAddress,
  };

  //   const {
  //     formData: formDataAddCreditCard,
  //     formErrors: formErrorsAddCreditCard,
  //     setErrors: setErrorsAddCreditCard,
  //     onChangeFormInput: onChangeFormInputAddCreditCard,
  //     isFormValid: isFormValidAddCreditCard,
  //     resetForm: resetFormAddCreditCard,
  //   } = useForm({
  //     initialData: {
  //       number: '',
  //       date: '',
  //       cvv: '',
  //       name: '',
  //       document: '',
  //     },
  //     validators: {
  //       number: [validators.required, validators.cardNumber],
  //       date: [validators.required, validators.cardExpirationDate],
  //       cvv: [validators.required, validators.cvv],
  //       name: [validators.required],
  //       document: [validators.required, validators.cpforcnpj],
  //     },
  //     formatters: {
  //       number: updateMaskCard,
  //       date: expirationDateMask,
  //       cvv: updateMaskCVV,
  //       name: updateHolderName,
  //       document: cpfCnpjMask,
  //     },
  //   });

  //   const onShowAddCreditCard = (): void => {
  //     showModal({
  //       value: ShowModalProfile.ADD_CREDIT_CARD,
  //       title: 'Adicionar novo cartão',
  //     });
  //   };

  //   const onShowDeleteCreditCard = (id: string): void => {
  //     setCardId(id);
  //     showModal({
  //       value: ShowModalProfile.CONFIRM_DELETE_CREDIT_CARD,
  //       title: '',
  //     });
  //   };

  //   const onSubmitDeleteCreditCard = async (): Promise<void> => {
  //     try {
  //       dispatch(setLoading(true));
  //       await api.delete<CardClient>(`client/my-account/card/${cardId}`);
  //       await getUserData();
  //       onSetVisible(false);
  //       dispatch(setLoading(false));
  //       toast.success('Cartão removido com sucesso!');
  //     } catch (error) {
  //       dispatch(setLoading(false));
  //     }
  //   };

  //   const controllerCreditCard: ControllerCreditCard = {
  //     list: client.cards ? client.cards : [],
  //     // onShowAddCreditCard: onShowAddCreditCard,
  //     // onShowDeleteCreditCard: onShowDeleteCreditCard,
  //     onSubmitDeleteCreditCard: onSubmitDeleteCreditCard,
  //     controllerAddCreditCard: controllerAddCreditCard,
  //   };

  const handleOnShowDeleteProfile = (): void => {
    showModal({
      value: ShowModalProfile.CONFIRM_DELETE_PROFILE,
      title: '',
    });
  };

  const handleOnSubmitDeleteProfile = async (): Promise<void> => {
    try {
      dispatch(setLoading(true));
      await api.delete<CardClient>('client/my-account');
      onSetVisible(false);
      dispatch(setLoading(false));
      toast.success('Conta excluida com sucesso!');

      removeItem(String(REACT_APP_AUTH));
      removeItem(String(REACT_APP_USER));
      history('/');
      // eslint-disable-next-line
    } catch (error) {
      dispatch(setLoading(false));
    }
  };

  const controllerDeleteProfile: ControllerDeleteProfile = {
    onShowDeleteProfile: handleOnShowDeleteProfile,
    onSubmitDeleteProfile: handleOnSubmitDeleteProfile,
  };

  useEffect(() => {
    getUserData();
    // eslint-disable-next-line
  }, []);

  return (
    <ProfileContainer
      state={loading}
      controllerPersonalInfo={controllerPersonalinfo}
      disableName={disableName}
      disableBirthDate={disableBirthDate}
      disableMotherName={disableMotherName}
      controllerChangePassword={controllerChangePassoword}
      controllerAddress={controllerAddress}
      controllerModalProfile={controllerModalPayment}
      //   controllerCreditCard={controllerCreditCard}
      controllerDeleteProfile={controllerDeleteProfile}
      onSubmitPersonalInfo={handleOnSubmitPersonalInfo}
      onSubmitChangePassword={onSubmitChangePassword}
      onChangeCEP={handleOnChangeCEP}
      onSubmitAddress={onSubmitAddress}
    />
  );
};

import React, { JSX, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDialog } from '@/hooks/useDialog';
import { Order } from '@/model/Order';
import { OrderResponse } from '@/model/OrderResponse';
import { setLoading, useLoading } from '@/redux/loading/loadingSlice';
import { api } from '@/services/api';
import { ShouldShowModalProps, ShowModalTickets, UseModalControl } from '../../types';
import { TicketsOrder } from './ui';

export const TicketsOrderScreen: React.FC = (): JSX.Element => {
  //   const { onShowSidebar } = useSidebar();
  const { visible, onSetVisible, onToggle, title, onChangeTitle } = useDialog();
  const [shouldShowModal, setShouldShowModal] = useState<ShowModalTickets>(
    ShowModalTickets.TICKET_CANCEL_AFTER,
  );
  const [orders, setOrders] = useState<OrderResponse[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [order, setOrder] = useState<Order>({} as Order);
  const { loading } = useSelector(useLoading);
  const dispatch = useDispatch();
  const history = useNavigate();
  //   const [ordersTemp, setOrdersTemp] = useState<Page<Order, Order>>(
  //     {} as Page<Order, Order>
  //   );

  // eslint-disable-next-line
  const showModal = ({ value, title }: ShouldShowModalProps): void => {
    setShouldShowModal(value);
    onChangeTitle(title);
    onSetVisible(true);
  };

  const controllerModalTicket: UseModalControl = {
    visible,
    titleDefine: title,
    shouldShowModal,
    onShouldShowModal: showModal,
    onToggleModal: onToggle,
    onSetVisible,
  };

  const getOrders = async (): Promise<void> => {
    dispatch(setLoading(true));
    const response = await api.get<OrderResponse[]>('/order/list');
    setOrders(response.data);
    dispatch(setLoading(false));
  };

  const handleOnShowDetailTicket = (item: string): void => {
    history(`/ingressos/detalhe/${item}`);
  };

  //   const handleOnShowPrintTicket = (item: Order): void => {
  //     history.push(`/ingressos/imprimir/${item.id}`);
  //   };

  //   const handleOnShowTranferTicket = (item: Order): void => {
  //     history.push(`/ingressos/tranferir/${item.id}`);
  //   };

  //   const {
  //     formData: formDataFilter,
  //     formErrors: formErrorsFilter,
  //     setErrors: setErrorsFilter,
  //     onChangeFormInput: onChangeFormInputFilter,
  //     isFormValid: isFormValidFilter,
  //     resetForm: resetFormFilter,
  //   } = useForm({
  //     initialData: {
  //       name: '',
  //       date: '',
  //     },
  //     validators: {
  //       name: [],
  //       date: [validators.maxLength(10)],
  //     },
  //     formatters: {
  //       date: updateMaskDate,
  //     },
  //   });

  //   const handleOnSubmitFilter = () => {
  //     try {
  //       if (isFormValidFilter()) {
  //         let ordersFilter: Order[] | undefined = [];
  //         ordersFilter =
  //           orders.list &&
  //           orders.list.filter((filter) => {
  //             if (
  //               controllerFilter.formData.date &&
  //               controllerFilter.formData.date ===
  //                 dayjs(filter.orderDate).locale('pt-br').format('DD/MM/YYYY')
  //             ) {
  //               return filter;
  //             }

  //             if (
  //               controllerFilter.formData.name &&
  //               filter.events[0].event.name
  //                 .toLocaleLowerCase()
  //                 .includes(controllerFilter.formData.name.toLocaleLowerCase())
  //             ) {
  //               return filter;
  //             }
  //           });
  //         setOrders({ ...orders, list: ordersFilter });
  //       }
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  //   const handleOnClearFilter = () => {
  //     resetFormFilter();
  //     setOrders(ordersTemp);
  //   };

  //   const controllerFilter: UseFormControl = {
  //     formData: formDataFilter,
  //     formErrors: formErrorsFilter,
  //     setErrors: setErrorsFilter,
  //     onChangeFormInput: onChangeFormInputFilter,
  //     isFormValid: isFormValidFilter,
  //     resetForm: resetFormFilter,
  //   };

  useEffect(() => {
    getOrders();
    // eslint-disable-next-line
  }, []);

  return (
    <TicketsOrder
      state={loading}
      orders={orders}
      order={order}
      controllerModalTicket={controllerModalTicket}
      onShowDetailTicket={handleOnShowDetailTicket}
      // controllerCancelTicketBefore={controllerCancelTicketBefore}
      //   onShowCancelTicket={handleOnShowCancelTicket}
      //   onSubmitCancelTicket={handleSubmitCancelTicket}

      //   onShowPrintTicket={handleOnShowPrintTicket}
      //   onShowTransferTicket={handleOnShowTranferTicket}
      //   onPressTutotialApp={handleTutotialApp}
      //   controllerFilter={controllerFilter}
      //   onSubmitFilter={handleOnSubmitFilter}
      //   onClearFilter={handleOnClearFilter}
    />
  );
};

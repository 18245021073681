import React, { JSX } from 'react';
import mestre1 from '../../../../../assets/images/mestre/mestre-1.png';
import mestre2 from '../../../../../assets/images/mestre/mestre-2.png';
import mestre3 from '../../../../../assets/images/mestre/mestre-3.png';

export const MestreDionizioUI: React.FC<object> = (): JSX.Element => (
  <section className="w-full">
    <div className="container w-full md:max-w-[1110px] mx-auto flex flex-col items-center">
      <div className="p-8 md:flex-shrink-0">
        <div className="text-[#013209] font-extrabold text-3xl sm:text-4xl lg:text-5xl tracking-tight text-center dark:text-white ">
          Mestre Dionizio
        </div>
        <div className="mt-2 flex flex-col md:flex-row">
          <div className="md:w-3/4 text-lg">
            <p className="mt-6 text-[#013209]max-w-3xl mx-auto dark:text-slate-400 text-justify">
              Mestre Dionizio teve contato pela primeira vez com a capoeira em 1969 aos 12 anos de
              idade, quando estava em um clube na cidade de Taguatinga em Brasília-DF quando parou
              para ver alguns capoeiristas que ali se apresentavam. A partir daquele dia dizia
              sempre a sua mãe que queria aprender a capoeira, mas na época não existia ninguém por
              perto que pudesse ensinar-lhe os primeiros passos, fazendo com que sua mãe não desse
              muita importância a seu pedido. Mesmo assim em 1971, acabou por descobrir um pessoal
              de homens já formados que se reuniam para treinar aos finais de semana no colégio que
              ele estudava, na época CTN - Colégio Taguatinga Norte, assistiu algumas aulas
              incentivado por um colega da escola que já treinava. Nesta época ele comprou um disco
              de vinil denominado {'Eu Bahia'} que o ouvia quase todo santo dia, os toques e as
              cantigas ali reproduzidas. Ele ensaiava a ginga e alguns golpes, como também tentava
              reproduzir os toques em um berimbau feito por ele mesmo de galho de goiabeira, arame
              de alumínio e a metade da casca de um coco seco como a cabaça.
            </p>
            <p className="mt-6 text-[#013209]max-w-3xl mx-auto dark:text-slate-400 text-justify">
              Em 1973, sua família mudou-se para a cidade do Guará e o mestre acabou retomando o
              contato com a capoeira através de alguns colegas que se reuniam para treinar no fundo
              do quintal da casa de um já capoeirista de nome Renato conhecido como {'tatu'} vindo
              do Rio de Janeiro, já falecido. Em 1974 frequentou algumas aulas que eram dadas por um
              capoeirista conhecido como
              {'Dentinho'} também aos finais de semana no Colégio GG - Guará I, atual sede do seu
              trabalho.
            </p>
            <p className="mt-6 text-[#013209]max-w-3xl mx-auto dark:text-slate-400 text-justify">
              Em 1976, o Mestre Dionizio entrou para servir a Aeronáutica e em 1978 entrou para a
              Faculdade de Educação Física e treinava sozinho esporadicamente, quando foi treinar
              com Mestre Tranqueira já falecido, no Sesc 504 da asa sul, onde foi considerado pelo
              mestre como aluno graduado, na época o mestre Tranqueira não usava nenhum tipo de
              sistema de graduação.
            </p>
            <p className="mt-6 text-[#013209]max-w-3xl mx-auto dark:text-slate-400 text-justify">
              O Mestre Zulu realizava anualmente em Brasília a Grande Roda Brasileira de Capoeira,
              que reunia diversos capoeiristas de Brasília e de vários estados, foi quando o Mestre
              Dionizio simpatizando com a metodologia e a filosofia do mestre Zulu passou a
              frequentar a sua academia, na época situada na quadra 704 da asa norte, em meados de
              1980, como também passou a integrar o Clube de Capoeira Beribazu, fundado pelo mestre
              Zulu em 1972. Em dezembro de 1988 foi confirmado como contra-mestre pelo mestre Zulu e
              em agosto de 1991 formou-se mestre obtendo o grau de corda vermelha.
            </p>
            <p className="mt-6 text-[#013209]max-w-3xl mx-auto dark:text-slate-400 text-justify">
              Já com título de professor de capoeira, de 1982 a 1984 ministrava aulas de capoeira em
              uma entidade social situada em Ceilândia cidade satélite de Brasília, desenvolvendo um
              trabalho com crianças de risco social. Somente em novembro de 1987 já professor
              concursado da Rede Pública do DF e contra-mestre, foi convidado pela Secretaria de
              Educação do DF (antiga Fundação Educacional) a fundar o Centro de Iniciação Desportiva
              em Capoeira do Guará, onde atua até a presente data, e como fruto deste tão suado e
              árduo trabalho em setembro de 2007, formou seu primeiro mestre, hoje chamado Mestre
              IGOR, tendo também um belo grupo de graduados e contra-mestres.
            </p>
            <p className="mt-6 text-[#013209]max-w-3xl mx-auto dark:text-slate-400 text-justify">
              O mestre Dionizio mantém intercâmbio com vários grupos de capoeira de diferentes
              vertentes, participando de eventos aqui no Brasil e exterior como convidado para
              ministrar vivências work shops e palestras sobre a capoeira, atividade física através
              da capoeira, cultura e arte. Dentre os países podemos destacar, EUA (Nova York); Japão
              (Mie Ken), as cidades e capitais brasileiras como: São Paulo (Campinas, Jundiaí);
              Paraná (Curitiba); Espírito Santo (Vitória); Santa Catarina (Florianópolis);
              Pernambuco (Recife e Gravatá); Salvador; Mato Grosso (Cuiabá); Rondônia (Porto Velho),
              Rio de Janeiro (Niterói); Minas Gerais (Belo Horizonte e Ouro Preto); Goiás (Goiânia,
              São João D&apos;Aliança e Alto do Paraíso) e Ceará (Fortaleza).
            </p>
            <p className="mt-6 text-[#013209]max-w-3xl mx-auto dark:text-slate-400 text-justify">
              Em 2013 houve a cerimônia de outorga à Mestre Dignificador, sendo condecorado com a
              corda branca.
            </p>
            <p className="mt-6 text-[#013209]max-w-3xl mx-auto dark:text-slate-400 text-justify">
              Mestre Dionizio é professor de Educação Física, pós-graduado em capoeira na Escola, já
              ministrou aulas em diversas academias e escolas da rede particular, como: Colégio Cor
              Jesu, Colégio Católica, Colégio La Salle. Atualmente é professor aposentado da
              Secretaria de Educação do DF e leciona Capoeira no Guará para crianças, jovens e
              adultos.
            </p>
          </div>
          <div className="md:w-1/4 ml-5 h-[700px] md:mt-6 ">
            <img className="mt-6 mb-6 md:mt-0 align-top" src={mestre1} alt="Descrição da imagem" />
            <img className="mt-6 mb-6 md:mt-0" src={mestre2} alt="Descrição da imagem" />
            <img className="mt-6 mb-6 md:mt-0" src={mestre3} alt="Descrição da imagem" />
          </div>
        </div>
      </div>
    </div>
  </section>
);

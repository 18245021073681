import React, { JSX, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { OrderResponse } from '@/model/OrderResponse';
import { setLoading } from '@/redux/loading/loadingSlice';
import { api } from '@/services/api';
import { TicketsUI } from './ui';

export const TicketsScreens: React.FC = (): JSX.Element => {
  const [order, setOrder] = useState<OrderResponse>();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();

  const handleOrder = async (): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const response = await api.get<OrderResponse>(`/order/${id}`);
      if (response) {
        setOrder(response.data);
      }
    } catch (error) {
      // eslint-disable-next-line
      console.error('Erro ao buscar o Inscrição:', error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handlePaymentStatus = (status: string): string => {
    switch (status) {
      case 'APPROVED':
        return 'Aprovado';
      case 'DISAPPROVED':
        return 'Rejeitado';
      case 'AWAITING_PAYMENT':
        return 'Aguardando Pagamento';
      case 'CANCELED':
        return 'Cancelado';
      case 'CANCELLATION_ANALYSIS':
        return 'Análise de Cancelamento';
      case 'EXPIRED':
        return 'Expirado';
      default:
        return 'Status Desconhecido';
    }
  };

  useEffect(() => {
    handleOrder();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {order ? (
        <TicketsUI order={order} paymentStatus={handlePaymentStatus} />
      ) : (
        <div>Carregando...</div>
      )}
    </>
  );
};

import { IconWarning } from '@/assets/icons/IconWarning';
import { Button } from '@/components/Button';
import React, { JSX } from 'react';

interface Props {
  onCancelConfirm: () => void;
  onOkConfirm: () => void;
}

export const ConfirmDeleteProfileContent: React.FC<Props> = ({
  onCancelConfirm,
  onOkConfirm,
}): JSX.Element => (
  <div className="w-full mx-auto text-center">
    <div className="flex flex-col justify-center">
      <IconWarning height="63px" className="self-center text-[#D8231B] mb-[30px]" />
      <h4 className="font-dmsans font-bold text-neutro-b-400 text-[21px] mb-[20px]">
        Você tem certeza que quer excluir a sua conta?
      </h4>
      <p className="font-dmsans text-base font-normal text-neutro-w-600 mb-[70px]">
        Todos os seus dados serão <strong>excluídos permanentemente</strong>, sem a possiblidade de
        recuperá-los.
      </p>
      <div className="grid grid-cols-2 gap-5">
        <Button
          type="button"
          title="Não, quero manter a minha conta"
          buttonStyle="outlined"
          size="lg"
          className="w-full mb-[25px]"
          onClick={onCancelConfirm}
        />
        <Button
          type="button"
          title="Sim, quero excluir a minha conta"
          buttonStyle="primary"
          size="lg"
          className="w-full mb-[25px]"
          onClick={() => onOkConfirm()}
        />
      </div>
    </div>
  </div>
);

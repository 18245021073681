import React, { JSX } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import {
  MdArticle,
  MdBrowserUpdated,
  MdContactMail,
  MdEvent,
  MdGroups2,
  MdHome,
  MdInsights,
  MdLogout,
  MdMenuBook,
  MdPerson,
  MdShoppingCart,
  MdAddCard,
  MdAssignment,
  MdAccessibilityNew,
} from 'react-icons/md';
import { AuthUser } from '@/model/AuthUser';
import { Close } from '@/assets/icons';
import { classNames } from '@/helpers/common';

export interface SidebarProps {
  signed: boolean;
  visible: boolean;
  user: AuthUser;
  onSetVisible?: (open: boolean) => void;
  handleOnSignIn?: () => void;
  handleOnLogout?: () => void;
  goTo: (href: string) => void;
}

export const Menu = ({
  signed,
  visible,
  user,
  onSetVisible,
  handleOnSignIn,
  handleOnLogout,
  goTo,
}: SidebarProps): JSX.Element => (
  <Dialog.Root open={visible} onOpenChange={onSetVisible}>
    <Dialog.Portal>
      <Dialog.Overlay className="fixed inset-0 bg-modal-overlay">
        <Dialog.Content className="fixed inset-0 z-10">
          <div className="flex h-full items-start justify-end text-left sm:items-center sm:p-0 md:radix-state-closed:translate-x-[244px] md:radix-state-open:translate-x-0 animate-enter-from-right">
            <div className="relative z-50 pt-[46px] w-full h-full md:w-[244px] bg-white font-dmsans shadow-xl rounded-lg">
              <div className="flex flex-col justify-between items-center">
                <div className="self-end p-0 pr-[40px]">
                  <Dialog.Close asChild>
                    <img src={Close} className="cursor-pointer" alt="button closer" />
                  </Dialog.Close>
                </div>
              </div>
              <nav className="flex flex-col mx-10 font-dmsans">
                <a
                  href="#"
                  tabIndex={-1}
                  onClick={() => goTo('/')}
                  className="text-base font-bold mb-[17px] cursor-pointer flex items-center"
                >
                  <MdHome className="mr-2" /> Inicio
                </a>
                <a
                  href="#"
                  tabIndex={-1}
                  onClick={() => goTo('/historia')}
                  className="text-base font-bold mb-[17px] cursor-pointer flex items-center"
                >
                  <MdMenuBook className="mr-2" /> História
                </a>
                <a
                  className="text-base font-bold mb-[17px] cursor-pointer flex items-center"
                  href="#"
                >
                  <MdGroups2 className="mr-2" /> O Grupo
                </a>
                <a
                  className="text-base font-bold mb-[17px] ml-2 cursor-pointer flex items-center"
                  onClick={() => {
                    goTo('/mestre-dionizio');
                  }}
                >
                  <MdPerson className="mr-2" /> Mestre Dionizio
                </a>
                <a
                  className="text-base font-bold ml-2 mb-[17px] cursor-pointer flex items-center"
                  onClick={() => {
                    goTo('/graduacao');
                    if (onSetVisible) {
                      onSetVisible(false);
                    }
                  }}
                >
                  <MdInsights className="mr-2" /> Sistema de Graduação
                </a>
                <a
                  href="#"
                  tabIndex={-1}
                  onClick={() => {
                    goTo('/eventos');
                  }}
                  className="text-base font-bold mb-[17px] cursor-pointer flex items-center"
                >
                  <MdEvent className="mr-2" /> Eventos
                </a>
                <a
                  href="#"
                  tabIndex={-1}
                  onClick={() => {
                    goTo('/articles');
                  }}
                  className="text-base font-bold mb-[17px] cursor-pointer flex items-center"
                >
                  <MdArticle className="mr-2" /> Artigos
                </a>
                <a
                  href="#"
                  tabIndex={-1}
                  onClick={() => {
                    goTo('#');
                  }}
                  className="text-base font-bold mb-[17px] cursor-pointer flex items-center"
                >
                  <MdBrowserUpdated className="mr-2" /> Mídia
                </a>
                <a
                  href="#"
                  tabIndex={-1}
                  onClick={() => {
                    goTo('#');
                  }}
                  className="text-base font-bold mb-[17px] cursor-pointer flex items-center"
                >
                  <MdContactMail className="mr-2" /> Contato
                </a>
              </nav>

              {!signed ? (
                <nav className="flex flex-col text-center mx-10 font-dmsans mt-[40px]">
                  <button
                    id="btnDoLogin"
                    className={classNames(
                      'px-10 py-2 transition-colors duration-150 rounded-lg focus:shadow-outline bg-neutro-b-400 hover:bg-neutro-b-600 active:bg-neutro-b-400 text-base font-bold text-white',
                    )}
                    onClick={handleOnSignIn}
                  >
                    Entrar
                  </button>
                  <a
                    href="#"
                    className={classNames('font-dmsans bg-white text-black font-bold p-5 mt-4')}
                    onClick={() => goTo('/criar-conta')}
                  >
                    Criar conta
                  </a>
                </nav>
              ) : (
                <>
                  <hr className="m-2" />
                  <nav className="flex mt-3 flex-col mx-10 font-dmsans">
                    {user && user.type.toString() === 'TEACHER' ? (
                      <>
                        <a
                          href="#"
                          tabIndex={-1}
                          onClick={() => goTo('/inscricoes')}
                          className="text-base font-bold mb-[17px] cursor-pointer flex items-center"
                        >
                          <MdAssignment className="mr-2" /> Inscrições
                        </a>

                        <a
                          href="#"
                          tabIndex={-1}
                          onClick={() => goTo('/eventos-create')}
                          className="text-base font-bold mb-[17px] cursor-pointer flex items-center"
                        >
                          <MdEvent className="mr-2" /> Criar Evento
                        </a>
                        <a
                          href="#"
                          tabIndex={-1}
                          onClick={() => goTo('/alunos')}
                          className="text-base font-bold mb-[17px] cursor-pointer flex items-center"
                        >
                          <MdPerson className="mr-2" /> Alunos
                        </a>
                        <a
                          href="#"
                          tabIndex={-1}
                          onClick={() => goTo('/treinos')}
                          className="text-base font-bold mb-[17px] cursor-pointer flex items-center"
                        >
                          <MdAccessibilityNew className="mr-2" /> Treinos
                        </a>
                        <a
                          href="#"
                          tabIndex={-1}
                          onClick={() => goTo('/criar-pix')}
                          className="text-base font-bold mb-[17px] cursor-pointer flex items-center"
                        >
                          <MdAddCard className="mr-2" /> Criar Pix
                        </a>
                      </>
                    ) : (
                      ''
                    )}
                    <a
                      href="#"
                      tabIndex={-1}
                      onClick={() => goTo('/pedidos')}
                      className="text-base font-bold mb-[17px] cursor-pointer flex items-center"
                    >
                      <MdShoppingCart className="mr-2" /> Meus Pedidos
                    </a>
                  </nav>
                  <nav className="flex flex-col text-center mx-10 font-dmsans mt-[40px]">
                    <a
                      href="#"
                      className={classNames(
                        'font-dmsans bg-white text-black font-bold p-5 mt-[40px] flex items-center justify-center',
                      )}
                      onClick={handleOnLogout}
                    >
                      <MdLogout className="mr-2" /> Sair
                    </a>
                  </nav>
                </>
              )}
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Overlay>
    </Dialog.Portal>
  </Dialog.Root>
);

import React from 'react';
import { CheckoutScreen } from '@/features/checkout/screens/Checkout';
import { CreateAccountScreen } from '@/features/createAccount/Screens/CreateAccount';
import { EventScreen } from '@/features/event/screens/Event';
import { EventsScreen } from '@/features/event/screens/Events';
import { ArticlesScreen } from '@/features/home/screens/Articles';
import { GraduationScreen } from '@/features/home/screens/Graduation';
import { GroupScreen } from '@/features/home/screens/Group';
import { HomeScreen } from '@/features/home/screens/Home';
import { MestreDionizioScreen } from '@/features/home/screens/MestreDionizio';
import { MusicScreen } from '@/features/home/screens/Music';
import { ProfileScreen } from '@/features/profile/screens/Profile';
import { TicketListScreen } from '@/features/tickets/screens/TicketList';
import { TicketsOrderScreen } from '@/features/tickets/screens/TicketOrder';
import { TicketsScreens } from '@/features/tickets/screens/Tickets';

export const routes = [
  {
    path: '/inscrição/:id',
    Component: <CheckoutScreen />,
    isPrivate: true,
  },
  {
    path: '/criar-conta',
    Component: <CreateAccountScreen />,
    isPrivate: false,
  },
  {
    path: '/eventos',
    Component: <EventsScreen />,
    isPrivate: false,
  },
  {
    path: '/evento/:id',
    Component: <EventScreen />,
    isPrivate: false,
  },
  //   {
  //     path: '/eventos-create',
  //     Component: <CreateEventScreen />,
  //     isPrivate: false,
  //   },
  {
    path: '/',
    Component: <HomeScreen />,
    isPrivate: false,
  },
  {
    path: '/historia',
    Component: <GroupScreen />,
    isPrivate: false,
  },
  {
    path: '/mestre-dionizio',
    Component: <MestreDionizioScreen />,
    isPrivate: false,
  },
  {
    path: '/graduacao',
    Component: <GraduationScreen />,
    isPrivate: false,
  },
  {
    path: '/articles',
    Component: <ArticlesScreen />,
    isPrivate: false,
  },
  {
    path: '/musicas',
    Component: <MusicScreen />,
    isPrivate: false,
  },
  {
    path: '/profile',
    Component: <ProfileScreen />,
    isPrivate: true,
  },

  {
    path: '/ingressos/detalhe/:id',
    Component: <TicketsScreens />,
    isPrivate: true,
  },
  {
    path: '/pedidos',
    Component: <TicketsOrderScreen />,
    isPrivate: true,
  },
  {
    path: '/inscricoes',
    Component: <TicketListScreen />,
    isPrivate: true,
  },
];

import React, { Fragment, JSX } from 'react';
import { Route, Routes } from 'react-router-dom';
import { getBoolean } from '../helpers/common/localStorage';
import { REACT_APP_AUTH } from '../utils/config';
import PrivateRoute from './PrivateRoute';
import { routes } from './Routes';

const AuthorizedRoutes: React.FC = (): JSX.Element => {
  const signed = getBoolean(String(REACT_APP_AUTH));
  //   const user: AuthUser = getItem(String(REACT_APP_USER));

  let isAuthenticated = false;
  if (signed) {
    isAuthenticated = true;
  }

  return (
    <>
      <Routes>
        {routes.map(({ path, Component, isPrivate }, i) => (
          <Fragment key={i}>
            {isPrivate ? (
              <Route
                path={path}
                element={<PrivateRoute element={Component} isAuthenticated={isAuthenticated} />}
              />
            ) : (
              <Route path={path} element={Component} />
            )}
          </Fragment>
        ))}
      </Routes>
    </>
  );
};

export default AuthorizedRoutes;

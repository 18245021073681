import React, { JSX } from 'react';
import { Navigate } from 'react-router-dom';

interface PrivateRouteProps {
  element: JSX.Element;
  isAuthenticated: boolean;
}

// eslint-disable-next-line arrow-body-style
const PrivateRoute: React.FC<PrivateRouteProps> = ({ element, isAuthenticated }) => {
  // Retorna o componente ou redireciona
  return isAuthenticated ? element : <Navigate to="/" replace />;
};

export default PrivateRoute;

import React, { JSX } from 'react';
import { FaSpotify, FaYoutube, FaDeezer } from 'react-icons/fa';
import { MdAudiotrack } from 'react-icons/md';
import cd from '@/assets/images/cd.jpeg';

const MusicUI: React.FC = (): JSX.Element => (
  <div className="min-h-screen flex items-start justify-center bg-gray-100 pt-12">
    <div className="flex flex-col md:flex-row bg-white shadow-lg rounded-lg overflow-hidden max-w-4xl w-full">
      <div className="md:w-1/2 flex items-center justify-center bg-gray-200 p-4">
        <img src={cd} alt="Capa do CD" className="w-full h-auto rounded-lg" />
      </div>
      <div className="md:w-1/2 p-6 flex flex-col justify-center">
        <h1 className="text-3xl font-bold text-green-700 mb-2">
          Canto Raiz - <span className="text-orange-600">Mestre Dionizio</span>
        </h1>
        <p className="text-lg text-gray-700 mb-6">Em breve em todas as plataformas digitais</p>
        <div className="flex justify-center space-x-8">
          <MdAudiotrack size={40} className="text-blue-500" aria-label="Audiotrack" />

          <a
            href="https://open.spotify.com/intl-pt/album/4szDAPsjy66DOXnWf09OXo"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaSpotify size={40} className="text-green-500" aria-label="Spotify" />
          </a>

          <a
            href="https://www.youtube.com/playlist?list=OLAK5uy_mHis9h2GdEBmYfUCt5Qyr-PLxt3OKe41Y"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaYoutube size={40} className="text-red-600" aria-label="YouTube" />
          </a>

          <a
            href="https://www.deezer.com/pt/album/652389861"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaDeezer size={40} className="text-fuchsia-600" aria-label="Deezer" />
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default MusicUI;

import React, { FormEvent, JSX, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormInputNameRegister, ShouldShowModalProps } from '@/features/core/auth/types';
import { updateMask as updateMaskCEP } from '@/helpers/masks/cep';
import { updateMask as updateMaskCPF } from '@/helpers/masks/cpf';
import { unmask, updateMask as updateMaskDate } from '@/helpers/masks/generalDate';
import { updateMask as updateMaskPhone } from '@/helpers/masks/mobilePhone';
import validators from '@/helpers/validators';
import useForm from '@/hooks/useForm';
import { setLoading, useLoading } from '@/redux/loading/loadingSlice';
import { AuthWrapper } from '@/features/core/auth/screens/AuthWrapper';
import cep from 'cep-promise';
import { toast } from 'react-toastify';
import { useDialog } from '@/hooks/useDialog';
import { Address } from '@/model/Address';
import { Mestre } from '@/model/Mestre';
import { Client } from '@/model/User';
import { UserBasic } from '@/model/UserBasic';
import { AxiosError, api } from '@/services/api';
import { CreateAccountUi, NameFiles, OptionProps } from './ui';

export const CreateAccountScreen: React.FC = (): JSX.Element => {
  const { loading } = useSelector(useLoading);
  const [shouldShowPasswordToText, setShouldShowPasswordToText] = useState(false);
  const [shouldShowPasswordToText2, setShouldShowPasswordToText2] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { visible, onSetVisible } = useDialog();
  const dispatch = useDispatch();
  const [formNameFiles, setFormNameFiles] = useState<NameFiles>({});
  const [mestres, setMestres] = useState<OptionProps[]>([]);

  const handleOnTogglePasswordToText = (): void =>
    setShouldShowPasswordToText(!shouldShowPasswordToText);

  const handleOnTogglePasswordToText2 = (): void =>
    setShouldShowPasswordToText2(!shouldShowPasswordToText2);

  const handleMestres = async (): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const response = await api.get<Mestre>(`/user/mestre`);
      const { data } = response;
      if (Array.isArray(data) && data.length > 0) {
        const mestre: OptionProps[] = [];
        data.forEach(dataItem => {
          mestre.push({
            value: dataItem.id,
            label: `Mestre ${dataItem.nickname}`,
          });
        });
        setMestres(mestre);
      } else {
        setMestres([]);
      }
      // eslint-disable-next-line
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const {
    formData: formDataRegister,
    formErrors: formErrorRegister,
    setErrors: setErrorRegister,
    onChangeFormInput: onChangeFormInputRegister,
    isFormValid: isFormValidRegister,
    resetForm: resetFormRegister,
  } = useForm({
    initialData: {
      mestre: '',
      name: '',
      nickname: '',
      document: '',
      email: '',
      gender: '',
      date: '',
      phone: '',
      graduation: '',
      corda: '',
      password: '',
      confirmPassword: '',
      terms: '',
      zipCode: '',
      state: '',
      city: '',
      district: '',
      street: '',
      complement: '',
      addressnumber: '',
      imageBase64: '',
      country: '',
    },
    validators: {
      name: [validators.required],
      nickname: [validators.required],
      document: [validators.required, validators.cpf],
      email: [validators.required, validators.email],
      gender: [validators.required],
      date: [validators.required, validators.birthday, validators.maxLength(10)],
      phone: [validators.required, validators.mobilePhone],
      graduation: [validators.required],
      corda: [validators.required],
      password: [
        validators.required,
        validators.minLength(8),
        validators.maxLength(15),
        validators.hasPasswordOnlyNumberCharacteres,
      ],
      confirmPassword: [
        validators.required,
        validators.minLength(8),
        validators.maxLength(15),
        validators.hasPasswordOnlyNumberCharacteres,
      ],
      terms: [validators.required],
      zipCode: [validators.required],
      state: [validators.required],
      city: [validators.required],
      district: [validators.required],
      street: [validators.required],
      addressnumber: [validators.required],
      country: [validators.required],
    },
    formatters: {
      document: updateMaskCPF,
      phone: updateMaskPhone,
      date: updateMaskDate,
      zipCode: updateMaskCEP,
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const showModal = ({ value, title }: ShouldShowModalProps): void => {
    onSetVisible(true);
  };

  const handleOnChangeFileInput =
    (inputName: string) =>
    (file: File | undefined): void => {
      // validate if file is image
      if (file && file.type.match(/image\/(jpg|jpeg|png)/)) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64 = reader.result?.toString();
          if (base64) {
            setFormNameFiles({ ...formNameFiles, [inputName]: file.name });
            onChangeFormInputRegister(inputName)('');
            onChangeFormInputRegister(inputName)(base64);
          }
        };
      } else {
        setErrorRegister({
          [inputName]: ['O formato deve ser .jpg, .jpeg ou .png'],
        });
      }
    };

  const handleOnSubmitRegister = async (e: FormEvent): Promise<void> => {
    try {
      e.preventDefault();
      if (isFormValidRegister()) {
        dispatch(setLoading(true));
        const dateString = unmask(formDataRegister[FormInputNameRegister.date]);
        if (!dateString) {
          const fielName = FormInputNameRegister.date.toString();
          setErrorRegister({ [fielName]: ['Data de nascimento Inválida'] });
          return;
        }
        if (
          formDataRegister[FormInputNameRegister.password] !==
          formDataRegister[FormInputNameRegister.confirmPassword]
        ) {
          setErrorRegister({
            [FormInputNameRegister.password]: ['Senha Diferentes'],
            [FormInputNameRegister.confirmPassword]: ['Senha Diferentes'],
          });
          return;
        }
        // const date = new Date(dateString);
        const address = {
          zipcode: formDataRegister[FormInputNameRegister.zipCode],
          state: formDataRegister[FormInputNameRegister.state],
          city: formDataRegister[FormInputNameRegister.city],
          district: formDataRegister[FormInputNameRegister.district],
          street: formDataRegister[FormInputNameRegister.street],
          complement: formDataRegister[FormInputNameRegister.complement],
          number: formDataRegister[FormInputNameRegister.addressnumber],
          country: formDataRegister[FormInputNameRegister.country],
        } as Address;
        const payload: UserBasic = {
          mestre: formDataRegister[FormInputNameRegister.mestre],
          name: formDataRegister[FormInputNameRegister.name],
          nickname: formDataRegister[FormInputNameRegister.nickname],
          cpf: formDataRegister[FormInputNameRegister.document],
          gender: formDataRegister[FormInputNameRegister.gender],
          email: formDataRegister[FormInputNameRegister.email],
          cellPhone: formDataRegister[FormInputNameRegister.phone],
          birthdate: dateString,
          graduation: formDataRegister[FormInputNameRegister.graduation],
          corda: formDataRegister[FormInputNameRegister.corda],
          password: formDataRegister[FormInputNameRegister.password],
          address,
          acceptedTerms: formDataRegister[FormInputNameRegister.terms] === 'true',
          image: formDataRegister[FormInputNameRegister.imageBase64],
        };

        const response = await api.post<Client>('/user', payload);
        if (response.status === 201) {
          resetFormRegister();
          toast.success('Dados salvos com sucesso!');
        } else if (response.status === 400) {
          const errorMessage =
            typeof response.data === 'string' ? response.data : JSON.stringify(response.data);
          toast.error(errorMessage);
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      //   console.error(error);
      let errorMessage = 'Falha ao realizar o cadastro, tente novamente mais tarde';
      if (error.response && error.response.data) {
        errorMessage =
          typeof error.response.data === 'string'
            ? error.response.data
            : JSON.stringify(error.response.data);
      }
      toast.error(errorMessage);
      setErrorRegister({
        document: [errorMessage],
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleOnChangeCEP = async (value: string): Promise<void> => {
    if (value.length === 9) {
      const cepResponse = await cep(value);
      onChangeFormInputRegister(FormInputNameRegister.state)(cepResponse.state);
      onChangeFormInputRegister(FormInputNameRegister.city)(cepResponse.city);
      onChangeFormInputRegister(FormInputNameRegister.district)(cepResponse.neighborhood);
      onChangeFormInputRegister(FormInputNameRegister.street)(cepResponse.street);
    }
  };

  useEffect(() => {
    handleMestres();
    // eslint-disable-next-line
  }, []);

  return (
    <AuthWrapper isHomepage={false}>
      <CreateAccountUi
        state={loading}
        formData={formDataRegister}
        formErrors={formErrorRegister}
        formNameFiles={formNameFiles}
        mestres={mestres}
        onChangeFormInput={onChangeFormInputRegister}
        shouldShowPasswordToText={shouldShowPasswordToText}
        shouldShowPasswordToText2={shouldShowPasswordToText2}
        onTogglePasswordToText={handleOnTogglePasswordToText}
        onTogglePasswordToText2={handleOnTogglePasswordToText2}
        onChangeCEP={handleOnChangeCEP}
        onShouldShowModal={showModal}
        onSubmitRegister={handleOnSubmitRegister}
        onChangeFormFileInput={handleOnChangeFileInput}
      />
    </AuthWrapper>
  );
};

import React, { JSX } from 'react';

interface IconProps extends React.SVGProps<SVGSVGElement> {
  children?: React.ReactNode;
}

export const IconLock: React.FC = (props: IconProps): JSX.Element => (
  <svg
    viewBox="0 0 12 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMaxYMax"
    {...props}
  >
    <path
      d="M10.6476 6.41649H9.56274L9.56259 4.16813C9.56259 2.2038 7.96426 0.605469 5.99993 0.605469C4.03559 0.605469 2.43726 2.2038 2.43726 4.16813V6.4166L1.35221 6.41674C0.606595 6.41674 0 7.02334 0 7.76895V14.8844C0 15.63 0.606595 16.2366 1.35221 16.2366H10.6477C11.3933 16.2366 11.9999 15.63 11.9999 14.8844L12 7.76858C12 7.02296 11.3934 6.41622 10.6478 6.41622L10.6476 6.41649ZM3.06113 4.16813C3.06113 2.54789 4.37953 1.22945 5.99981 1.22945C7.6201 1.22945 8.9385 2.54785 8.9385 4.16813V6.4166H3.06128L3.06113 4.16813ZM11.3754 14.8842C11.3754 15.2858 11.0488 15.6124 10.6472 15.6124L1.35176 15.6123C0.950197 15.6123 0.623532 15.2856 0.623532 14.8841L0.623677 7.76862C0.623677 7.36706 0.950342 7.0404 1.3519 7.0404H10.6473C11.0489 7.0404 11.3756 7.36706 11.3756 7.76862L11.3754 14.8842Z"
      fill="currentColor"
    />
    {props.children}
  </svg>
);
